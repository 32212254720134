import { Component } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User, UserEmail } from 'src/app/model/user';
import { BaseModalPageDirective } from 'src/app/pages/base-modal-page';

@Component({
  selector: 'app-modal-external',
  templateUrl: './modal-external.component.html',
  styleUrls: [ './modal-external.component.scss' ],
})
export class ModalExternalComponent extends BaseModalPageDirective<ModalExternalComponent, User> {
  public static open(matDialog: MatDialog): MatDialogRef<ModalExternalComponent, User> {
    return matDialog.open(ModalExternalComponent, this.createDialog<void>());
  }

  public email = '';
  public readonly customEmailErrorStateMatcher = new CustomEmailErrorStateMatcher();

  public constructor(matDialogRef: MatDialogRef<ModalExternalComponent>) {
    super(matDialogRef);
  }

  public confirm(): void {
    if (CustomEmailErrorStateMatcher.isValidEmail(this.email)) {
      this.close(new UserEmail(this.email));
    }
  }
}

class CustomEmailErrorStateMatcher implements ErrorStateMatcher {
  public static isValidEmail(email: string): boolean {
    const domain = email.split('@')[1];
    if (domain && domain.includes('.')) {
      const tld = domain.substring(domain.lastIndexOf('.') + 1);
      return tld.length > 1;
    } else {
      return false;
    }
  }

  public constructor() {}

  public isErrorState(control: AbstractControl<string> | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control === null || control.untouched) {
      return false;
    }
    return control.invalid || !CustomEmailErrorStateMatcher.isValidEmail(control.value);
  }
}
