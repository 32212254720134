<form #form="ngForm">
  <div mat-dialog-title>
    <h2>{{ 'bkwa.space2.external.title' | translate }}</h2>
    <button mat-icon-button type="button" (click)="cancel()" [matTooltip]="'bkwa.common.close' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <mat-form-field class="w-100">
      <label for="externalEmail">{{ 'bkwa.space2.external.email.label' | translate }}</label>
      <input
        #focus
        matInput
        type="email"
        id="externalEmail"
        name="externalEmail"
        [(ngModel)]="email"
        required
        email
        spellcheck="false"
        [errorStateMatcher]="customEmailErrorStateMatcher"
      />
      <mat-error>{{ 'bkwa.space2.external.email.error' | translate }}</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-flat-button color="accent" (click)="cancel()">{{ 'bkwa.common.cancel' | translate }}</button>
    <button type="submit" mat-flat-button color="primary" (click)="form.valid && confirm()">{{ 'bkwa.common.add' | translate }}</button>
  </mat-dialog-actions>
</form>
